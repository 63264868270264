<template>
    <div style="text-align: left;">
        <el-card shadow="never">
            <div class="pageCenter form-container">
                <el-form :model="brandForm" :rules="brandFormRules" ref="brandForm" label-width="120px">
                    <el-form-item>
                        <template v-if="!!brandId">
                            <h2>更新品牌信息</h2>
                            <span style="color: #ff0000;">品牌信息更新后，将重新进入审核状态</span>
                        </template>
                        <template v-else>
                            <h2>创建新的品牌</h2>
                        </template>
                    </el-form-item>
                    <el-form-item label="品牌图标" prop="brandIcon">
                        <div>说明：建议使用800*800像素，且大小限制1mb以内</div>
                        <uploadImage @uploadImageBack="uploadImageBack">
                            <img v-if="brandForm.brandIcon" :src="this.$oucy.ossUrl+brandForm.brandIcon" class="avatar">
                            <i v-else class="el-icon-s-flag avatar-uploader-icon"></i>
                        </uploadImage>
<!--                         <el-upload
                                accept="image/jpeg,image/gif,image/png"
                                :before-upload="handleOnBeforeUploadAndSquare"
                                class="avatar-uploader"
                                action="fakeaction"
                                :show-file-list="false"
                                :http-request="handleAvataIconUpload">
                            <img v-if="brandForm.brandIcon" :src="this.$oucy.ossUrl+brandForm.brandIcon" class="avatar">
                            <i v-else class="el-icon-s-flag avatar-uploader-icon"></i>
                        </el-upload> -->
                    </el-form-item>
                    <el-form-item label="品牌名称" prop="brandName">
                        <el-input v-model="brandForm.brandName" autocomplete="off" placeholder="请输入品牌名称" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="品牌分类" prop="brandClassify">
                        <el-radio-group v-model="brandForm.brandClassify">
                            <el-radio :label="0" border>家具类</el-radio>
                            <el-radio :label="1" border>材料类</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="品牌描述" prop="brandDesc">
                        <el-input type="textarea"
  :rows="2" v-model="brandForm.brandDesc" autocomplete="off" placeholder="请输入品牌描述" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="keywordFiltr('submitBrand')" type="primary">保存设置</el-button>
                        <el-button @click="$oucy.back()">取  消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
    import oss from "@/util/oss.js"
    import oucy from "../../../util/oucyUtil";
    import {Notification} from "element-ui";
    import {localSet, localGet, localDel} from "@/store/store"
    import uploadImage from "@/components/uploadImage.vue"

    export default {
        name: "AddBrand",
        components:{
            uploadImage
        },
        data(){
            const validateBrandName = (rule, value, callback) => {
                const me = this;
                if (!value) {
                    callback(new Error('请输入品牌名称'))
                } else {
                    oucy.postRequest('/client/product/furnitureglobalbrand/isBrandExist',{
                        id:me.brandId,
                        brandName: value
                    }).then(res=>{
                        if (res===true) {
                            callback();
                        } else {
                            callback(new Error('该品牌名称已存在'));
                        }
                    }).catch(err=>{
                        callback(new Error(err));
                    });
                }
            };
            return{
                brandId:null,
                brandForm:{
                    brandIcon:null,
                    brandName:null,
                    brandDesc:null,
                    brandClassify:0
                },
                brandFormRules:{
                    brandIcon: [
                        {required: true, message: '请设置品牌图标', trigger: 'blur'},
                    ],
                    brandName: [
                        {validator: validateBrandName, trigger: 'blur'},
                    ],
                    brandClassify: [
                        {required: true, message: '请选择品牌分类', trigger: 'blur'},
                    ],
                    brandDesc: [
                        {required: true, message: '请设置品牌描述', trigger: 'blur'},
                    ]
                }
            }
        },
        mounted() {
            const me = this;
            const brandId = me.$route.query.code;
            if(!!brandId){
                oucy.postRequest('/client/product/furnitureglobalbrand/getBrandById',{
                    id:brandId
                }).then(res=>{
                    console.log(res);
                    me.brandId = brandId;
                    me.brandForm.brandIcon = res.brandIcon;
                    me.brandForm.brandName = res.brandName;
                    me.brandForm.brandDesc = res.brandDesc;
                    me.brandForm.brandClassify = res.brandCategory;
                })
            }
        },
        methods:{
            keywordFiltr(fn){
                this.$oucy.keywordFiltr(this.brandForm).then(res=>{
                   this[fn]() 
                },err=>{})
            },
            handleOnBeforeUploadAndSquare: function(file){
                const me = this;
                return new Promise((resolve, reject) => {
                    me.$oucy.checkImgFile(file).then(res=>{
                        return me.$oucy.checkImgSquare(res, 1, 1);
                    }).then(res=>{
                        resolve(res);
                    }).catch(err=>{
                        reject(err);
                    });
                });

            },
            handleAvataIconUpload: function(params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res=>{
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res=>{
                    me.brandForm.brandIcon = res;
                });
            },
            submitBrand: function () {
                const me = this;
                let enterprise=localGet('enterprise')
                me.$refs["brandForm"].validate((valid) => {
                    if (valid) {
                        oucy.postRequest((!!me.brandId)?'/client/product/furnitureglobalbrand/updateFurnitureGlobalBrand':'/client/product/furnitureglobalbrand/addFurnitureGlobalBrand',{
                            enterpriseId:enterprise.id,
                            id:me.brandId,
                            brandName:me.brandForm.brandName,
                            brandIcon:me.brandForm.brandIcon,
                            brandDesc:me.brandForm.brandDesc,
                            brandCategory:me.brandForm.brandClassify
                        }).then(res=>{
                            Notification({
                                title: '操作成功',
                                message: '品牌信息已经提交，待审核通过后即可使用!',
                                type: 'success',
                                duration: 4000
                            });
                            oucy.back();
                        });
                    }
                });
            },
            // 上传回调
            uploadImageBack(v){
                this.brandForm.brandIcon=v
            },

        }
    }
</script>

<style scoped>
.form-container{
    width: 500px;
}
.avatar-uploader >>> .el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}
.avatar-uploader >>> .el-upload:hover {
    border-color: #409EFF;
}
</style>
